import React, { Component } from 'react';
import {
  IconCode,
  PageWithoutSubNav,
  Paragraph,
  PlatformTabs,
  Section,
} from 'docComponents';
import CodingWithUniform from './partials/_CodingWithUniform';
import Introduction from './partials/_Introduction';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import { setDesignCodePreference } from '../../utility/js/designCodePreference';
import styles from './index.module.scss';

export default class GetStartedCodeIndex extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setDesignCodePreference('code');
  }

  render() {
    return (
      <PageWithoutSubNav pageType="Code" title="Get Started" designCode>
        <Section>
          <div className={styles.mainTitle}>
            <IconCode className={styles.iconCode} />
            <CodingWithUniform />
          </div>
        </Section>

        <Section title="Introduction">
          <Introduction role="code" />
        </Section>

        <Section title="Installation">
          <Paragraph>
            Uniform is built with web in mind, but other platform components are
            being added all the time.{' '}
            <strong>Choose your platform for setup instructions</strong>.
          </Paragraph>

          <PlatformTabs>
            <WebTab type="web" />
            <ReactNativeTab type="reactnative" />
            <AndroidTab type="android" />
            <AppleTab type="apple" />
            <OtherTab type="other" />
          </PlatformTabs>
        </Section>
      </PageWithoutSubNav>
    );
  }
}

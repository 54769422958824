import React from 'react';
import { IconCode, Paragraph, PlatformTab } from 'docComponents';
import styles from '../index.module.scss';

const OtherTab = () => {
  return (
    <PlatformTab>
      <div className={styles.otherTab}>
        <IconCode className={styles.iconOther} />
        <Paragraph className={styles.otherTabMessage}>
          We don’t have setup guides for any additional platforms yet.
        </Paragraph>
      </div>
    </PlatformTab>
  );
};

export default OtherTab;

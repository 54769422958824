import React from 'react';
import { CodeSnippet, Link, List, PlatformTab } from 'docComponents';

const snippet1 = require('raw-loader!../../../data/snippets/get-started-rn1.example');
const snippet2 = require('raw-loader!../../../data/snippets/get-started-rn2.example');
const snippet3 = require('raw-loader!../../../data/snippets/get-started-rn3.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <List type="ordered">
        <li>
          Follow Hudl's React Native{' '}
          <Link href="https://sync.hudl.com/x/6TwVAw">startup guide</Link>.
        </li>

        <li>
          Open your project and install the package via npm.
          <CodeSnippet
            code={snippet1}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Install and link up the react-native-svg library
          <CodeSnippet
            code={snippet3}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          After installation, you’ll have access to the classes and components
          in the <code>hudl-rn-uniform-ui</code> repo.
        </li>

        <li>
          Import any component snippets, like{' '}
          <Link href="/components/icon" isDesignCodeLink>
            icons
          </Link>{' '}
          or{' '}
          <Link href="/guidelines/colors" isDesignCodeLink>
            colors
          </Link>
          , to get started.
          <CodeSnippet
            code={snippet2}
            platform="web"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>
      </List>
    </PlatformTab>
  );
};

export default ReactNativeTab;

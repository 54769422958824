import React from 'react';
import { CodeSnippet, Link, List, PlatformTab } from 'docComponents';

const snippet = require('raw-loader!../../../data/snippets/get-started-android.example');

const AndroidTab = () => {
  return (
    <PlatformTab>
      <List type="ordered">
        <li>
          Our primary Android repos,{' '}
          <Link href="https://github.com/hudl/hudroid">Hudroid</Link> and{' '}
          <Link href="https://github.com/hudl/android-framework">
            Android-Framework
          </Link>
          , already have <code>uniform-ui</code> installed. When working in a
          different Android repo, add the following line to your project, making
          sure to use latest{' '}
          <Link href="https://github.com/hudl/android-framework/tree/master/uniform-ui">
            version number
          </Link>
          .
          <CodeSnippet
            code={snippet}
            platform="android"
            disableCodeEditing
            className="uni-margin--one--top"
          />
        </li>

        <li>
          Copy any code snippets, like{' '}
          <Link href="/guidelines/colors/identity" isDesignCodeLink>
            colors
          </Link>
          , to get started.
        </li>
      </List>
    </PlatformTab>
  );
};

export default AndroidTab;

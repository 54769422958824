import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
} from 'docComponents';

const snippet1 = require('raw-loader!../../../data/snippets/get-started-web1.example');
const snippet2 = require('raw-loader!../../../data/snippets/get-started-web2.example');
const snippet3 = require('raw-loader!../../../data/snippets/get-started-web3.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <Paragraph className="uni-margin--two--btm">
        Uniform is automatically distributed through our UI system for easy
        setup and maintenance. Every current and future Hudl repo will be setup
        automatically.
      </Paragraph>

      <Section>
        <SectionSubhead>React</SectionSubhead>
        <Paragraph>
          Our full suite of components is built in React and is the preferred
          method for building interfaces.
        </Paragraph>
        <List type="ordered">
          <li>
            In your react file, grab the components from the <code>window</code>{' '}
            object.
            <CodeSnippet
              code={snippet1}
              platform="react"
              disableCodeEditing
              className="uni-margin--one--top"
            />
          </li>

          <li>
            Use any component snippets, like{' '}
            <Link href="/components/type/text" isDesignCodeLink>
              text
            </Link>
            , to get started.
          </li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>CSS</SectionSubhead>
        <Paragraph>
          Uniform’s CSS provides style to the React components and additional
          classes for things like margin and padding. If you're unable to use
          React in your repo, the CSS classes are another way to get Uniform
          styling. To use these classes, add any <code>uni-</code> classes to
          your elements.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>uniCSS npm package</SectionSubhead>
        <Paragraph>
          Our npm package contains styling helpers like color variables, the
          z-index system and mixins.
        </Paragraph>
        <List type="ordered">
          <li>
            Install via npm.
            <CodeSnippet
              code={snippet2}
              platform="react"
              disableCodeEditing
              className="uni-margin--one--top"
            />
          </li>

          <li>
            Import the variables, mixins and functions at the top of your Sass
            files.
            <CodeSnippet
              code={snippet3}
              platform="react"
              disableCodeEditing
              className="uni-margin--one--top"
            />
          </li>

          <li>
            Reference Uniform variables, like{' '}
            <Link href="/guidelines/colors/identity" isDesignCodeLink>
              colors
            </Link>
            , to get started.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
